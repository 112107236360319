import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { get_autocomplete_request_body, get_autocomplete_onload_body, get_origin_url } from '../helper.js'
import axios from 'axios';
import { useSelector } from 'react-redux';

const AutocompleteInput = (props) => {

    const {apiSubModel,value,field,changeHandler,autoCompleteClass,makeDisable,baseUrl,prepopulateString} = props
    const [selectedUuid, setSelectedUuid] = useState('');
    const [displayValue, setDisplayValue] = useState({'memo': prepopulateString});
    const [options, setOptions] = useState([]);
    const [searchString,setSearchString] = useState(null)
    const [triggerSearch,setTriggerSearch] = useState(1)
    const user = useSelector((state)=> state.user)
    const dataStructure = useSelector((state)=> state.dataStructure)
    const ref = useRef()

  useEffect(()=>{
    const fetchUuidOnLoad = async () => {
      const url = (field.origin ? get_origin_url(field,dataStructure) : (baseUrl ? baseUrl : process.env.REACT_APP_LOCAL_BACKEND_URL)) + apiSubModel + '/search'
      const config = {
          headers: {'Authorization': 'Bearer ' + user.token},
          params: {}
      }
      if(value){
        let requestBody=get_autocomplete_onload_body(value)
        const response = await axios.post(url,requestBody,config)
        const recdata = response.data.result[0] // value is uuid
        if(recdata && recdata.uuid && recdata.memo){
          setSelectedUuid(recdata.uuid)
          setDisplayValue({'memo': recdata.memo})
        }
      }
    }
    setSelectedUuid(value)
      fetchUuidOnLoad()
  },[apiSubModel,value,user.token,dataStructure,field,baseUrl])

  useEffect(()=>{
    if(!selectedUuid && prepopulateString){
      setDisplayValue({'memo': prepopulateString})
    }
  },[prepopulateString, selectedUuid])

  const handleInputChange = (event) => {
    if(event){
      setOptions([])
      let newValue = event.target.value
      if(newValue===''){
        setSelectedUuid('')
        changeHandler('',field)
      }
      setSearchString(newValue)
      if(newValue === searchString) setTriggerSearch((triggerSearch)=> triggerSearch + 1)
    }
  }

  useEffect(()=>{
    const searchData = setTimeout(async()=>{

      try {
          const url = (field.origin ? get_origin_url(field,dataStructure) : (baseUrl ? baseUrl : process.env.REACT_APP_LOCAL_BACKEND_URL)) + apiSubModel + '/search'
          const config = {
                  headers: {'Authorization': 'Bearer ' + user.token},
                  params: {}
              }
          let requestBody=get_autocomplete_request_body(searchString,searchString?searchString.length:0)
          const response = await axios.post(url,requestBody,config)
          setOptions(response.data.result) // Assumendo che l'API restituisca un array di oggetti { id, displayname }
      } catch (error) {
          console.error('Errore nel recupero delle opzioni:', error);
      }
    
    },500)
    return () => clearTimeout(searchData)
  },[apiSubModel, baseUrl, dataStructure, field, searchString, user.token, triggerSearch])

  

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setSelectedUuid(newValue.uuid)
      changeHandler(newValue.uuid,field)
    }
  };

  return (
    <div>
      <Autocomplete ref={ref}
        /* id={apiSubModel+'_autocomplete'} */
        options={options}
        className={makeDisable?'autocomplete-disable':''}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.uuid}>
              {option.memo}
            </li>
          );
        }}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.memo?option.memo:''}
        value={displayValue}
        onChange={handleOptionSelect}
        onInputChange={handleInputChange}
        onFocus={handleInputChange}
        freeSolo={true}
        disabled={makeDisable}
        renderInput={(params) => (
          <TextField key={params.uuid} className={autoCompleteClass}
            {...params}
            placeholder="Cerca un elemento..."
            variant="standard"
          />
        )}
      />
      <input type="hidden" name={apiSubModel} value={selectedUuid} readOnly />
    </div>
  );
};

export default AutocompleteInput
