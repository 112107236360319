import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { setShow, logOut, setLoadedCount } from '../app/store'
import { useNavigate } from 'react-router-dom'

const Menu = (props) => {

const links = props.links
const menu = useSelector((state)=> state.menu)
const user = useSelector((state)=> state.user)
const dispatch = useDispatch()
const navigate = useNavigate()

const handleLogout = () => {
  dispatch(logOut())
  navigate(process.env.REACT_APP_LOCAL_SUBFOLDER+'/')
}

  return (
    <>
    {user.logged_in && <div className="sidebar-menu flex-grow-1">
        <ul className='ps-2 mt-4'>
        {links.map((link, index) => (
            link.url !== '/register' ? <li key={index} className='mt-2'>
            <NavLink to={link.url} className={'nav-link'} onClick={
              ()=>{
              dispatch(setShow(!menu.show))
              dispatch(setLoadedCount(0)) // Necessary to reset loaded count on page change
            }
              }>
                <span className="material-symbols-rounded shift-down">{link.nome_icona}</span> {link.nome_link} 
            </NavLink>
            </li> : ''
        ))}
        </ul>
        {
        user.logged_in && <><div className='w-100 mt-4'>
          <button className='mcr-btn small-btn' onClick={()=>{handleLogout()}}>Logout</button>
        </div></>
        }
    </div>}
    </>
  );
};

export default Menu;
