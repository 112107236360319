import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get_autocomplete_onload_body, get_origin_url } from '../helper.js'
import axios from 'axios';

const AutocompleteSubMultiTag = (props) => {
    const {apiSubModel,value,removeHandler,makeDisable,baseUrl,field} = props
    const [displayValue, setDisplayValue] = useState('')
    const [selectedUuid, setSelectedUuid] = useState('')
    const user = useSelector((state)=> state.user)
    const dataStructure = useSelector((state)=> state.dataStructure)

    useEffect(()=>{
        const fetchUuidOnLoad = async () => {
          const url = (field.origin ? get_origin_url(field,dataStructure) : (baseUrl ? baseUrl : process.env.REACT_APP_LOCAL_BACKEND_URL)) + apiSubModel + '/search'
          const config = {
              headers: {'Authorization': 'Bearer ' + user.token},
              params: {}
          }
          if(value){
            let requestBody=get_autocomplete_onload_body(value)
            const response = await axios.post(url,requestBody,config)
            const recdata = response.data.result[0] // value is uuid
            if(recdata && recdata.uuid && recdata.memo){
              setSelectedUuid(recdata.uuid)
              setDisplayValue(recdata.memo)
            }
          }
        }
        setSelectedUuid(value)
        fetchUuidOnLoad()
      },[apiSubModel, value, baseUrl, user.token, field, dataStructure])

  return (
        <div className='d-inline-block bg-gray rounded me-2 my-2 p-1'>
            <span className=''>{displayValue} </span>
            <button className='btn mcr-btn btn-micro-with-icon' disabled={makeDisable} onClick={()=>{removeHandler(selectedUuid)}}>
              <span className="material-symbols-rounded">close</span></button>
        </div>
  )
}

export default AutocompleteSubMultiTag
