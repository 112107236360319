import { hiddenFields, numberFields, timeFields, errorTable } from "./constants"

export const arrayToString = (arr) => {
    let str = ''
    if(arr.length){
        arr.forEach(
        (el,idx) => {
            if(idx===0){
            str+='['
            }
            if(idx===arr.length-1){
            str+=('"'+el+'"')
          } else{
            str+=('"'+el+'",')
          }
        }
      )
      str+=']'
    } else {
      str = '[]'
    }
    return str
}

export const get_error_message_by_code = (errCode) => {
    if(errCode.length === 4){
        for (let k in errorTable){
            if(errorTable[k].code === errCode){
                return errorTable[k].desc
            }
        }
    }
    return 'Errore sconosciuto, cod. '+errCode
}

export const get_origin_url = (field,dataStructure) => {
    if(!field.origin || !dataStructure.relatedUrls){
        return null
    }
    for(let k in dataStructure.relatedUrls){
        if (k === field.origin){
            return dataStructure.relatedUrls[k]
        }
    }
    return null
}

export const get_final_ui_structure = (uiStructure,structureData) => {
    if(!uiStructure || typeof uiStructure !== 'object'){
        return structureData
    } else {
        let finalUIstructure = []
        for (let k in uiStructure){
          finalUIstructure.push({name: uiStructure[k].name, /* format: uiStructure[k].format, */ section: true})
          for(let j in uiStructure[k].fields){
            let fieldName = uiStructure[k].fields[j].field
            for (let f in structureData){
              if (structureData[f].name === fieldName){
                finalUIstructure.push({...structureData[f], format: uiStructure[k].format})
              }
            }
          }
        }
        return finalUIstructure
    }
}
export const get_html_field_type = (field) => {
    let server_type = field.type,
        server_name = field.name
    if (hiddenFields.includes(server_name)) return 'hidden'
    if (numberFields.includes(server_type)) return 'number'
    if (server_type==='boolean') return 'checkbox'
    if (server_type==='datetime') return 'datetime-local'
    if (server_type==='time'||server_type==='timerange') return 'time'
    if (server_type==='date') return 'date'
    return 'text' 
}

export const get_field_value = (field,objectData) => {
    if (field.name==='price' && !isNaN(objectData[field.name]) && objectData[field.name]!==''){
        return(Math.round(objectData[field.name]*100)/100)
    }
    if (timeFields.includes(field.type)){
        if (!isNaN(objectData[field.name]) && objectData[field.name]!==null) {
            let o = null,
                fulltimestring = null
            if(field.type==='date'){
                o = new Date(objectData[field.name]*24*60*60*1000)
                fulltimestring = o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate();
            } else { // datetime
                o = new Date(objectData[field.name])
                fulltimestring = o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()+'T'+(o.getHours()<10?'0':'')+o.getHours()+':'+(o.getMinutes()<10?'0':'')+o.getMinutes();
            }
            return fulltimestring
        }
        return null
    }
    if(numberFields.includes(field.type)){
        if(isNaN(objectData[field.name]) || objectData[field.name]===null){
            return ''
        }
    }
    return objectData[field.name]
}

export const get_resource_filename = (field,fileInfos) => {
    for(let k in fileInfos){
        if (fileInfos[k].field_name === field.name && fileInfos[k].newFileName) {
            return fileInfos[k].newFileName
        }
    }
    return ''
}

export const get_field_apimodel = (field, structureData) => {
    let strData = restore_backend_structure(structureData)
    return strData[field.name].model?strData[field.name].model:field.name
}

export const get_post_object_field_value = (field,value) => {
    if (field.type==='datetime'){
        if (value===null) return null;
        let o = new Date(value)
        return o.getTime()
    }
    if (field.type==='date'){
        if (value===null) return null;
        let o = new Date(value)
        return Math.round(o.getTime()/(1000*60*60*24))
    }
    if (field.type==='boolean'){
        return (value==='true')?false:true
    }
    if (field.type==='number'){
        if (!isNaN(value)){
            return parseFloat(value)
        } else {
            return null
        }
    }
    if(field.type==='integer'){
        if (!isNaN(value)){
          return Math.round(parseFloat(value))
        } else {
          return null
        }
    }
    if(field.type==='positiveinteger'){
        if (!isNaN(value)){
          let checkvalue = Math.round(parseFloat(value))
          return (checkvalue === 0) ? null : checkvalue
        } else {
          return null
        }
    }
    if(field.type==='uuid[]'){
        if(value){
            try{
                let ar = JSON.parse(value)
                if (Array.isArray(ar)) return ar
                return null 
            } catch (error){
                console.log('Errore: un campo di tipo uuid[] ha ritornato un array non valido dopo la modifica')
            }
        } else {
          return null
        }
    }
    return value
}

export const check_field_validation = (structureData,objectData) => {
    let validated=[]
    for(let k in structureData){
        // List validation rules
        let required = structureData[k].required,
          min = structureData[k].min,
          max = structureData[k].max,
          // Check value against validation rules
          value = objectData[structureData[k].name],
          type = structureData[k].type,
          label = structureData[k].label ? structureData[k].label.charAt(0).toUpperCase()+structureData[k].label.slice(1) : structureData[k].name.charAt(0).toUpperCase()+structureData[k].name.slice(1)
        
          if((required && (value==='' || value === null || typeof value === 'undefined'))){
          validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+' è richiesto'})
      
        } else if(max && value > max){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il valore massimo per il campo '+label+' è '+max})

        } else if (min && value < min){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il valore minimo per il campo '+label+' è '+min})

        } else if (type==='uuid' && (value==='' || value===null || typeof value === 'undefined') && required) {
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
        
        } else if (type==='uuid[]' && (value==='' || value===null || typeof value === 'undefined') && required){
            validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
        
        } else if (type==='uuid[]' && (typeof value === 'object') && required) {

            if(typeof value === 'object' && !value.length){
                validated.push({'name':structureData[k].name,'valid':false,'message':'Il campo '+label+'+ è richiesto'})
            }
            if(typeof value === 'object' && value.length){
                let validElement = false
                for(let k in value){
                    if(value[k]){
                        validElement = true
                    }
                }
                if(!validElement)validated.push({'name':structureData[k].name,'valid':false,'message':'Selezionare almeno un elemento nel campo '+label})
            }

        } else {
            validated.push({'name':structureData[k].name,'valid':true})
        }          
    }
    return validated
}

export const get_field_isvalid = (field,validation) => {
    for(let k in validation){
        if (validation[k].name === field.name && validation[k].valid===false){
            return false
        }
    }
    return true
}

export const generate_rnd_string = (n) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < n; i++) {
      const rnd = Math.floor(Math.random() * chars.length)
      result += chars.charAt(rnd)
    }
    return result
  }

export const sha256_and_base64_encode = async (inputString) => {
    // Importa l'API Web Crypto
    const subtleCrypto = window.crypto.subtle
    // Codifica il messaggio come ArrayBuffer
    const msgBuffer = new TextEncoder().encode(inputString)
    // Calcola l'hash SHA-256
    const hashBuffer = await subtleCrypto.digest('SHA-256', msgBuffer)
    // Converti l'ArrayBuffer in una rappresentazione binaria (Uint8Array)
    const hashArray = new Uint8Array(hashBuffer)
    // Esegui l'encoding in base64 dell'hash
    const base64EncodedHash = btoa(String.fromCharCode(...hashArray))
    return base64EncodedHash
}

// Returns given date string as a timestamp of start of day
const convert_html_field_date_to_timestamp = (dateString,type) => {
    let o = new Date(dateString)
    if(o) {
        // If field type is date, return number of days
        if(typeof type !== 'undefined' && type === 'date') {
            return Math.round(o.getTime()/(1000*60*60*24))
        // If field type is datetime, retuen number of milliseconds
        } else {
            return o.getTime()
        }
    }
    return null
}

// Returns given date string as a timestamp of end of day
const convert_html_field_date_to_timestamp_endday = (dateString,type) => {
    let o = new Date(dateString)
    if(o) {
        // If field type is date, return number of days
        if(typeof type !== 'undefined' && type === 'date') {
            return Math.round(o.getTime()/(1000*60*60*24))
        // If field type is datetime, retuen number of milliseconds of starting of following day
        }
        o.setDate(o.getDate()+1)
        return o.getTime()
    }
    return null
}

const add_days_to_timestamp = (timeStamp,days,fieldtype) => {
    let o = null
    if(typeof fieldtype !== 'undefined' && fieldtype === 'date') {
        // Timestamp is in days
        o = new Date(timeStamp*1000*60*60*24)
    } else {
        o = new Date(timeStamp)
    }
    if(o) {
        if(typeof fieldtype !== 'undefined' && fieldtype === 'date') {
            return Math.round(o.getTime()/(1000*60*60*24))
        // If field type is datetime, retuen number of milliseconds of starting of following day
        }
        o.setDate(o.getDate()+days)
        return o.getTime()
    }
    return null
} 

const restore_backend_structure = (dataStructure) => {
    let aut = {}
    for (let k in dataStructure){
        aut[dataStructure[k].name]=dataStructure[k]
    }
    return aut
}

// This is just for single values, for intervals (even if artificially constructed) see 'get_backend_filter'
const get_backend_filter_value = (filterModelRow,fields,name) => {
    let frontendFilterType=filterModelRow['type']

    if(numberFields.includes(fields[name].type) && filterModelRow['filter']) return parseFloat(filterModelRow['filter'])
    
    if(filterModelRow['filter']){
        if (frontendFilterType ==='eq' ) return filterModelRow['filter']
        if (frontendFilterType !=='eq' ) return filterModelRow['filter'].trim()
    }
    
    if(filterModelRow['dateFrom']){
        let jsTimeStampStartDay = convert_html_field_date_to_timestamp(filterModelRow['dateFrom'],fields[name].type)
        if(frontendFilterType === 'gt' || frontendFilterType === 'lte'){
            let jsTimeStampEndDay = add_days_to_timestamp(jsTimeStampStartDay,1,fields[name].type)
            return jsTimeStampEndDay
        }
        return jsTimeStampStartDay
    }

    if (filterModelRow['type']==='true')return true
    if (filterModelRow['type']==='false')return false
}

const get_backend_filter_function = (gridfunction) => {
    if(gridfunction==='contains_b')return 'contains'
    //if(gridfunction==='equals')return 'eq'
    //if(gridfunction==='greaterThan')return 'gt'
    //if(gridfunction==='lessThan')return 'lt'
    //if(gridfunction==='greaterThanOrEqual')return 'gte'
    //if(gridfunction==='greaterThanOrEqual')return 'lte'
    if(gridfunction==='true'||gridfunction==='false')return 'is'
    return gridfunction
}

export const get_backend_sort_model = (columnState) => {
    let sortBy={
        sortColumn: null,
        sortOrder: null
    }
    for(let k in columnState){
        if(columnState[k]['sort']==='asc'||columnState[k]['sort']==='desc'){
            sortBy = {
                sortColumn: columnState[k]['colId'],
                sortOrder: columnState[k]['sort']
            }
        }
    }
    return sortBy
}

export const get_backend_filter = (filterModel,sortModel,freeSearchString,dataStructure,useLogUrl,additionalFilter) => {
    
    let fields = []
    if(filterModel){
        let namedDataStructure = restore_backend_structure(dataStructure)
        for (let k in filterModel){
            // Se filtro range, costruire i between con due campi gte e lte per lo stesso field
            if(filterModel[k]['type']==='inRange_b'){
                let filterRowFrom = {
                    field: k,
                    value: filterModel[k]['dateFrom']?convert_html_field_date_to_timestamp(filterModel[k]['dateFrom'],namedDataStructure[k].type):filterModel[k]['filter'],
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: filterModel[k]['dateTo']?convert_html_field_date_to_timestamp_endday(filterModel[k]['dateTo'],namedDataStructure[k].type):filterModel[k]['filterTo'],
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else if (filterModel[k]['type']==='eq' && filterModel[k]['dateFrom']) {
                let filterRowFrom = {
                    field: k,
                    value: convert_html_field_date_to_timestamp(filterModel[k]['dateFrom'],namedDataStructure[k].type),
                    func: 'gte'
                }
                let filterRowTo = {
                    field: k,
                    value: convert_html_field_date_to_timestamp_endday(filterModel[k]['dateFrom'],namedDataStructure[k].type),
                    func: 'lte'
                }
                fields.push(filterRowFrom)
                fields.push(filterRowTo)
            } else {
            // Se filtro normale, costruire il normale campo a filtro singolo
                let filterRow = {
                    field: k,
                    value: get_backend_filter_value(filterModel[k],namedDataStructure,k),
                    func: get_backend_filter_function(filterModel[k]['type'])
                }
                fields.push(filterRow)
            }
        }
    }
    let sort = {}
    if (sortModel && sortModel.sortColumn && sortModel.sortOrder){
        sort={"field": sortModel.sortColumn, "desc": sortModel.sortOrder === 'desc'?true:false}
    }
    let bkf = useLogUrl?{
        "format":"ui",
        "inputformat": "ui",
        "sort": sort,
        "offset": 0,
    }:{
        "query": freeSearchString,
        "format":"ui",
        "inputformat": "ui",
        "sort": sort,
        "offset": 0,
    }
    if(fields && fields.length){
        if(additionalFilter){
            bkf.filter={
                "func": "and",
                "fields": [
                    {
                        "func": "and",
                        "fields": fields
                    },
                    additionalFilter
                ]
            }
        } else {
            bkf.filter={
                "func": "and",
                "fields": fields
            }
        }
    } else if (additionalFilter){
        bkf.filter=additionalFilter
    }
    return bkf
}

export const split_sentence_to_words = (str) => {
    // Split the string using whitespace as a separator
    const words = str.split(/\s+/);
    // Trim leading and trailing whitespace from each word
    return words.map(word => word.trim());
  }

export const get_autocomplete_request_body = (searchString,stringLength) => {
    if (!searchString || !searchString.length){
        return {
            "query": searchString,
            "format": "autocomplete",
            "sort": {
                "field": "memo"
            },
            "limit": stringLength <= 3 ? 20 : 1000
        }
    }
    let words = split_sentence_to_words(searchString)
    let filter = []
    for(let k in words){
        if (words[k].length){
            filter.push({"field": "memo", "value": words[k], "func": "contains"})
        }
    }
    let b = {
        "filter":{"func":"and","fields":filter},
        "format": "autocomplete",
        "sort": {
            "field": "memo"
        },
        "limit": stringLength <= 3 ? 20 : 1000
    }
    return b
}

export const get_autocomplete_onload_body = (value) => {
    return {
        "filter":{
            "func": "and",
            "fields": [
                {
                    "field": "uuid",
                    "value": value,
                    "func": "eq"
                }
            ]
        },
        "format": "autocomplete",
        "offset": 0,
        "count": false
    }
}

export const get_user_filter = (userUuid) => {
    return {
        "func": "and",
        "fields": [
            {
                "field": "user",
                "value": userUuid,
                "func": "eq"
            }
        ]
    }
}

export const get_chunkarray_by_fileinfo = (fileInfo,chunkArrayGroup) => {
    for(let k in chunkArrayGroup){
        if (chunkArrayGroup[k].field_name === fileInfo.field_name) return chunkArrayGroup[k].chunks
    }
    return null
}

export const get_html_field_classname = (field,creating) => {
    if (get_html_field_type(field)==='datetime-local' && (field.name==='deleted')){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='datetime-local' && (field.name==='created' && creating)){
        return 'd-none' //'form-control'
    }
    if (get_html_field_type(field)==='checkbox'){
        return 'form-check-input mcr-checkbox d-block'
    }
    return 'form-control'
}

export const get_html_fieldlabel_classname = (field,creating) => {
    if (get_html_field_type(field,creating)==='hidden'){
        return 'd-none'
    }
    if (get_html_field_classname(field,creating) === 'd-none'){
        return 'd-none'
    }
    if(field.name==='uuid'){ // May be not hidden for index table use
        return 'd-none'
    }
    return 'form-label text-even mt-3'
}

export const get_checked = (field,objectData) => {
    let type=get_html_field_type(field),
        value=get_field_value(field,objectData)
    if(type==='checkbox'&&value===true)return true;
    return false;
}

export const convertTimestampToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}

export const convertDateOnlyToReadable = (numberOfDays) => {
    let timestamp = numberOfDays*24*60*60*1000;
    const date = new Date(timestamp);
    const options = { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleString('it-IT', options);
}

export const convertTimeOnlyToReadable = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleString('it-IT', options);
}

export const filterTypedValue = (field) => {
    if (field.value && field.type === 'number') {
        field.value = field.value.replace(",", ".")
    }
    if(field.value && parseInt(field.minLength) <= parseInt(field.value.length) && field.min && parseFloat(field.value) < parseInt(field.min)) return field.min
    if(field.value && field.max && parseFloat(field.value) > parseInt(field.max)) return field.max
    return field.value
}
