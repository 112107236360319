import React, { useState, useEffect } from 'react';
import AutocompleteSubMultiSelect from './AutocompleteSubMultiSelect'
import AutocompleteSubMultiTag from './AutocompleteSubMultiTag';
import { arrayToString } from '../helper';

const MultiAutoComplete = (props) => {

  const {apiSubModel,fieldName,initMultiValue,field,changeHandler,isValid,makeDisable,baseUrl} = props

  const [selectedUuids,setSelectedUuids] = useState('')
  const [multiValue,setMultiValue] = useState(initMultiValue.length?initMultiValue:[])

  

  useEffect(()=>{
    if(multiValue && Array.isArray(multiValue)){
      let selectedUuidsString = arrayToString(multiValue)
      setSelectedUuids(selectedUuidsString)
    }
  },[multiValue])

  const singleChangeHandler = (singleUuid) => {
    let newMultiValue = []
    for(let k in multiValue){
      newMultiValue.push(multiValue[k])
    }
    if(!newMultiValue.includes(singleUuid) && singleUuid) {
      newMultiValue.push(singleUuid)
    }
    setMultiValue(newMultiValue)
    changeHandler(arrayToString(newMultiValue),field)
  }

  const handleRemove = (uuid) => {
    let newMultiValue = []
    for(let k in multiValue){
      if(multiValue[k]!==uuid){
        newMultiValue.push(multiValue[k])
      }
    }
    setMultiValue(newMultiValue)
    changeHandler(arrayToString(newMultiValue),field)
  }

  return (
    <>
      <div className={'container px-0' + (isValid ? '' : ' is-invalid')}>
        <input type="hidden" name={fieldName} value={selectedUuids} className='bg-lightgray form-control' readOnly />
        
        <AutocompleteSubMultiSelect
          makeDisable={makeDisable}
          apiSubModel={apiSubModel}
          fieldName={apiSubModel}
          value={''}
          autoCompleteClass={'form-control material-form-control' + (makeDisable?' custom-disabled':'')}
          field={field}
          singleChangeHandler={singleChangeHandler}
          baseUrl={baseUrl}
        />
        <div className='container bg-lightgray border border-dark rounded pb-4' style={{position: 'relative'}}>
          {multiValue && Array.isArray(multiValue) && multiValue.map((singleValue, idx) => {
            return <AutocompleteSubMultiTag
              key={idx}
              pos={idx}
              apiSubModel={apiSubModel}
              value={singleValue}
              baseUrl={baseUrl}
              field={field}
              removeHandler={handleRemove}
            ></AutocompleteSubMultiTag>
          })}
          {!multiValue.length && <p className='my-2'>Nessun elemento aggiunto</p>}
          {!isValid && <span className="material-symbols-rounded down-left">error</span>}
        </div>
      </div>
    </>
  )
}

export default MultiAutoComplete
