import React from 'react'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const ResetPasswordRequest = () => {

  const [username,setUsername] = useState("")
  const [validMail,setValidMail] = useState(false)
  const [touchedMail,setTouchedMail] = useState(false)
  const [sentRequest,setSentRequest] = useState(false)

  const navigate = useNavigate()

  const checkValidMail = (value) => {
      let rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
      if(rx.test(value)) return true
      return false
  }

  const handleMailChange = (e) => {
    let target = e.target
    setUsername(e.target.value)
    if(checkValidMail(target.value)){
      setValidMail(true)
    } else {
      setValidMail(false)
    }
    if(!touchedMail){
      setTouchedMail(true)
    }
  }

  const pwdRequest = async(baseurl,username) => {
    const url = baseurl + 'passwordreset/request';
    const config = {
      params: {}
    }
    const objectData = {
      "username": username
    }
    try{
      const response = await axios.post(url,objectData,config)
      const recdata = response.data
      if(recdata && recdata.result === true) {
        return {"status": 'ok'}
      } else {
        return {"error": 'Errore di rete durante la richiesta di password reset'}
      }
    } catch(error){
      if(error.response.data.error.description){
        return {"error": error.response.data.error.description}
      } else {
        return {"error": 'Errore di rete durante la richiesta di password reset'}
      }
    }
  }

  const handlePwdRequest = async(e) => {
    e.preventDefault()
    setSentRequest(true)
    let result = await pwdRequest(process.env.REACT_APP_LOCAL_AUTH_URL,username)
    if(result.error){

      setTimeout(()=>{setSentRequest(false)},3000);
      toast.error('Errore durante la richiesta di reset: '+result.error)
    } else {
        toast.success('Reset richiesto con successo. Controlla la casella email!')
    }
  }
  
  const backtoHome = (e) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <div className={'hero hero d-flex justify-content-center align-items-center'}>
        <ToastContainer
          className='toast-lowered'
          position='top-center'
          theme='dark'
        />
          {<>
            <div className='container-sm' style={{maxWidth: '450px'}}>
              <div className='row'>
                <div className='col-12 text-center p-3 wl-form rounded'>
                <button className='btn mcr-btn border-even btn-with-icon mt-2' title="Torna alla home" onClick={(e) => {backtoHome(e)}}><span className="material-symbols-rounded">home</span></button>

                  <h4 className='h4 mb-3 mt-2'>Inserisci la tua email qui sotto per richiedere il reset della password:</h4>
                    <div className='col-12'>
                      <div className={'mt-1 mb-2'}>
                        <form className='wl-form border-even rounded shadow p-2'>
                          <div className='row mt-3 mb-2'>
                            <div className='col-3 d-flex align-items-center'>
                            <label className="form-label" htmlFor="birth_date">Email:</label>
                            </div>
                            <div className='col-9'>
                            <input type='text' autoComplete='email' className={'form-control' + (!validMail && touchedMail ? ' is-invalid': '')} name='username' placeholder='' onKeyUp={(e)=>{handleMailChange(e)}} />
                            </div>
                            <div className='col-12'>
                                <span className={'text-even float-end' + (validMail || !touchedMail ? ' d-none': '')}>{'Inserisci una mail valida!'}</span>
                                <span className={'float-end' + (!validMail && touchedMail ? ' d-none': ' text-wl')}>{'|'}</span>
                            </div>
                          </div>
                          <div className='row mt-3 mb-2'>
                          <div className='col-12 text-center'>
                            <button disabled={!validMail || sentRequest} className='btn mcr-btn mx-0 font-futura border-even' onClick={(e) => handlePwdRequest(e)}>Richiedi reset</button>
                          </div>
                          </div>
                        </form>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </>}
          

        </div>
  )
}

export default ResetPasswordRequest
