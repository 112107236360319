import axios from 'axios'
import { get_error_message_by_code } from './helper';

export const createResource = async(MEDIA_URL,apiModel,token) => {
    const url = MEDIA_URL + apiModel + '/create/1';
    const config = {
      headers: {'Authorization': 'Bearer ' + token},
      params: {}
    }
    try{
      const response = await axios.get(url,config)
      const recdata = response.data.result[0]
      if(recdata && recdata.uuid){
        return {"uuid": recdata.uuid}
      } else {
        return {"error": 'Errore di rete generando la risorsa!'}
      }
    } catch(error){
      if(error.response.data.error.code){
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      } else {
        return {"error": 'Errore di rete generando la risorsa!'}
      }
    }
}

export const uploadFileChunks = async(MEDIA_URL,fileInfo,chunks,token) => {
  const url = MEDIA_URL + fileInfo.field_model + '/upload/' + fileInfo.uuid;
  const config = {
    headers: {'Authorization': 'Bearer ' + token},
    params: {}
  }
  for(let k in chunks){
    let postData = {"chunk": chunks[k]}
    try{
      const response = await axios.post(url,postData,config)
      const recdata = response.data
      if(recdata === true){
        if(parseInt(k) === (chunks.length)-1){
          return {"status": 'ok'}
        } else {
          continue
        }
      } else {
        return {"error": 'Errore di rete inviando il file!'}
      }
    } catch(error){
      if(error.response.data.error.code){
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      } else {
        return {"error": 'Errore di rete inviando il file!'}
      }
    }
  }
}

export const saveResource = async(MEDIA_URL,fileInfo,parent_id,parent_model,parent_origin,token) => {
  const url = MEDIA_URL + fileInfo.field_model + '/save';
  const config = {
    headers: {'Authorization': 'Bearer ' + token},
    params: {}
  }
  let postData = [{
    "uuid": fileInfo.uuid,
    "parent_uuid": parent_id,
    "parent_model": parent_model,
    "parent_origin": parent_origin,
    "filename": fileInfo.newFileName,
    "mimetype": fileInfo.mimetype,
    "committed": 1
  }]
  try{
    const response = await axios.post(url,postData,config)
    const recdata = response.data
    if(recdata && recdata.result === true) {
      return {"status": 'ok'}
    } else {
      return {"error": 'Errore di rete salvando il file!'}
    }
  } catch(error){
    if(error.response.data.error.code){
      if (error.response.data.error.code === 'E110'){ // This code (unable to commit twice) has to be ignored, procedure is successful anyway
        return {"status": 'ok'}
      } else {
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      }
    } else {
      return {"error": 'Errore di rete salvando il file!'}
    }
  }
}

export const saveModel = async(baseurl,apiModel,objectData,token) => {
  const url = baseurl + apiModel + '/save';
  const config = {
    headers: {'Authorization': 'Bearer ' + token},
    params: {}
  }
  try{
    const response = await axios.post(url,[objectData],config)
    const recdata = response.data
    if(recdata && recdata.result === true) {
      return {"status": 'ok'}
    } else {
      return {"error": 'Errore di rete salvando il '+ apiModel}
    }
  } catch(error){
    if(error.response.data.error.code){
      if (error.response.data.error.code === 'E110'){ // This code (unable to commit twice) has to be ignored, procedure is successful anyway
        return {"status": 'ok'}
      } else {
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      }
    } else {
      return {"error": 'Errore di rete salvando il '+ apiModel}
    }
  }
}

export const deleteModel = async(baseurl,apiModel,objectData,token) => {
  const url = baseurl + apiModel + '/save';
  const config = {
    headers: {'Authorization': 'Bearer ' + token},
    params: {}
  }
  try{
    const response = await axios.post(url,[objectData],config)
    const recdata = response.data
    if(recdata && recdata.result === true) {
      return {"status": 'ok'}
    } else {
      return {"error": 'Errore di rete cancellando il '+ apiModel}
    }
  } catch(error){
    if(error.response.data.error.code){
      if (error.response.data.error.code === 'E111'){ // This code (unable to commit twice) has to be ignored, procedure is successful anyway
        return {"status": 'ok'}
      } else {
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      }
    } else {
      return {"error": 'Errore di rete cancellando il '+ apiModel}
    }
  }
}

export const deleteResource = async(MEDIA_URL,fileInfo,token) => {
  const url = MEDIA_URL + fileInfo.field_model + '/save';
  const config = {
    headers: {'Authorization': 'Bearer ' + token},
    params: {}
  }
  // If old file doesn't exist, don't try to delete
  if(!fileInfo.old_uuid) return {"status": 'ok'}
  let postData = [{
    "uuid": fileInfo.old_uuid,
    "deleted": 1
  }]
  try{
    const response = await axios.post(url,postData,config)
    const recdata = response.data
    if(recdata && recdata.result === true) {
      return {"status": 'ok'}
    } else {
      console.warn('Errore di rete cancellando il file!')
      //return {"error": 'Errore di rete cancellando il file!'}
      return {"status": 'ok'}
    }
  } catch(error){
    if(error.response.data.error.code){
      if (error.response.data.error.code === 'E111'){ // This code (unable to delete twice) has to be ignored, procedure is successful anyway
        return {"status": 'ok'}
      } else {
        console.warn(get_error_message_by_code(error.response.data.error.code))
        //return {"error": get_error_message_by_code(error.response.data.error.code)}
        return {"status": 'ok'}
      }
    } else {
      console.warn('Errore di rete cancellando il file!')
      //return {"error": 'Errore di rete salvando il file!'}
      return {"status": 'ok'}
    }
  }
}

export const getUserInfo = async(authUrl,token) => {
  const url = authUrl + 'session/tokeninfo?access_token=' + encodeURIComponent(token);
  try{
    const response = await axios.get(url)
    const recdata = response.data
    if(recdata && recdata.user) {
      return {"userdata": recdata}
    } else {
      return {"error": 'Non è stato possibile recuperare le informazioni utente!'}
    }
  } catch(error){
    if(error.response.data.error.code){
        return {"error": get_error_message_by_code(error.response.data.error.code)}
    } else {
      return {"error": 'Non è stato possibile recuperare le informazioni utente!'}
    }
  }
}

export const loadModels = async (modelUrl,apiModel,uuids,token) => {
  const url = modelUrl + apiModel + '/load'
  const config = {
      headers: {'Authorization': 'Bearer ' + token},
      params: {}
  }
  if(uuids){
    let requestBody
    if(Array.isArray(uuids)) {
      requestBody=uuids
    } else {
      requestBody=[uuids] // If it's a single uuid string, enclose in array
    }
    try {
      const response = await axios.post(url,requestBody,config)
      const recdata = response.data
      if(recdata){
        return recdata
      } else {
        return {"error": 'Non è stato possibile caricare ' + apiModel}
      }
    } catch (error) {
      if(error.response.data.error.code){
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      } else {
        return {"error": 'Non è stato possibile caricare ' + apiModel}
      }
    }
  } else {
    return {"error": 'Per caricare ' + apiModel + ' macano gli uuid'}
  }
}
