import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Redirector = () => {

    const [searchParams] = useSearchParams();

    const navigate = useNavigate()
    useEffect(()=>{
        navigate('/dashboard'+(searchParams ? '?'+searchParams : ''))
    },[navigate, searchParams])
  return (
    <div>Attendere prego...</div>
  )
}

export default Redirector