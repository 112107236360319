import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import { logOut } from '../app/store'
import { ReactComponent as Spinner } from '../loading_spinner.svg'

const ConfirmUser = () => {

  const user = useSelector((state)=> state.user)
  const dispatch = useDispatch()

  const [accountActivated,setAccountActivated] = useState(false)
  const [displayModal,setDisplayModal] = useState(false)

  const { token } = useParams()

  const activateUrl = process.env.REACT_APP_LOCAL_USER_URL + 'user/activate'

  useEffect(()=>{
    if(user.logged_in){
      dispatch(logOut());
    }
  },[dispatch,user.logged_in])

  useEffect(()=>{
    const activateAccount = async (token) => {
      const url = activateUrl
      const postData = {
        "token": token
      }
      try{
        const response = await axios.post(url,postData)
        const recdata = response.data
            if (recdata) {
                if(recdata.result){
                    setDisplayModal(true)
                    setAccountActivated(true)
                } else {
                    setDisplayModal(true)
                }
            }
      } catch (error) {
          if(error.response.data.error){
              console.log(error.response.data.error.description)
              toast.error(error.response.data.error.description)
          } else {
              console.log('Errore nell\'attivazione dell\'account')
              toast.error('Errore nell\'attivazione dell\'account')
          }
      }
    }

    if (token.length){
      activateAccount(token)
    } else {
      // If token is 0 length, display the non valid message
      setDisplayModal(true)
    }
  },[token,activateUrl])

  return (
    <div className={'hero d-flex justify-content-center align-items-center font-futura'}>
      <ToastContainer
          className='toast-raised'
          position='bottom-right'
          theme='dark'
        />

      <div className={"modal custom-modal" + (displayModal ? "" : " fade")} id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content wl-form text-white shadow">
                <div className="modal-header justify-content-center">
                    {accountActivated && <h5 className="modal-title">Attivazione account conclusa!</h5>}
                    {!accountActivated && <h5 className="modal-title">Link di attivazione non valido o già utilizzato!</h5>}
                </div>
                <div className="modal-body text-center">
                {!accountActivated && <h6 className='h6'>Se sei sicuro/a che il tuo account non sia già attivo, ti consigliamo di contattare l'assistenza per richiedere un nuovo link</h6>}
                <NavLink className='even-link d-block mb-5' to='/'><span className="material-symbols-rounded text-even d-block mt-5">home</span>
                    {accountActivated && <h6 className='h6'>Torna alla home per accedere</h6>}
                    {!accountActivated && <h6 className='h6'>Torna alla home</h6>}
                  </NavLink>
                </div>
                <div className="modal-footer" style={{height: '50px'}}>
                </div>
            </div>
          </div>
        </div>

        {!displayModal && <div className='text-center wl-form rounded shadow'>
            <h2 className='my-3 mx-4'>Attendi mentre attiviamo il tuo account...</h2>
            <Spinner className='mb-3' height={64} width={64}/>
            {/* <NavLink style={{textDecoration: 'none', fontSize: '24px'}} to={'/dashboard'}>
                <span className="material-symbols-rounded" style={{position: 'relative', top: '3px'}} >home</span> Torna alla home</NavLink> */}
        </div>}
    </div>
  )
}

export default ConfirmUser