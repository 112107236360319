import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import { loadModels } from '../common'
import { setSaved } from '../app/store'

const Miniprofile = (props) => {

    const {setTriggerReloadCounters,HtmlTooltip} = props

    const apiModel = 'user'

    const initialUserState = {
        uuid: '',
        birth_date: '',
        tax_id_code: '',
        address_street: '',
        address_number: '',
        address_city: '',
        address_zip: '',
    }
    const [userData,setUserData] = useState(initialUserState)
    const [userProfileCompleted,setUserProfileCompleted] = useState(false)
    const [userLoaded,setUserLoaded] = useState(false)
    const [canSubmitUser,setCanSubmitUser] = useState(false)
    const initialValidationState = {
        'uuid':{valid: false,touched: false, message: ''},
        'birth_date': {valid: false,touched: false, message: 'Inserisci una data valida, devi avere almeno 18 anni'},
        'tax_id_code': {valid: false,touched: false, message: 'Inserisci un codice fiscale valido'},
        'address_street': {valid: false,touched: false, message: 'Inserisci la via/piazza, ecc.'},
        'address_number': {valid: false,touched: false, message: 'Inserisci il numero civico'},
        'address_city': {valid: false,touched: false, message: 'Inserisci la città di residenza'},
        'address_zip': {valid: false,touched: false, message: 'Inserisci un CAP valido'},
    }
    const [validation, setValidation] = useState(initialValidationState)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()

// Load user data first time
useEffect(()=>{
    const includedFieldsArray = ['uuid','birth_date','tax_id_code','address_street','address_number','address_city','address_zip']
    if(!userLoaded){
        loadModels(process.env.REACT_APP_LOCAL_AUTH_URL,apiModel,user.id,user.token).then((result) => {
            if(result && result.result){
                let usr = result.result,
                    tmp_user = initialUserState,
                    profileCompleted = false
                for(let k in usr){
                    for(let j in usr[k]){
                        if(j === 'profile_completed' && usr[k][j]) profileCompleted = true
                    }
                }
                if(profileCompleted){
                    setUserProfileCompleted(true)
                } else {
                    for(let k in usr){
                        for(let j in usr[k]){
                            if(includedFieldsArray.includes(j)) {
                                if(j === 'birth_date'){
                                    let o = new Date(usr[k][j]*1000*60*60*24),
                                    nd = o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()
                                    tmp_user[j] = nd
                                } else {
                                    tmp_user[j] = usr[k][j]
                                }
                            }
                        }
                    }
                    for(let k in tmp_user){
                        check_validation({"name":k,"value":tmp_user[k]})
                    }
                    setUserData(tmp_user)
                }
                setUserLoaded(true)
            } else {
                if(result.error){
                    console.log('Impossibile caricare il profilo utente: '+result.error)
                    toast.error('Impossibile caricare il profilo utente: '+result.error)
                }
            }
        })
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[user.id, user.token, userData, userLoaded])

const checkIfCanSubmitUser = () => {
    const checkValidationStatus = () => {
        for(let k in validation){
            if(!validation[k].valid) return false
        }
        return true
    }
    if(checkValidationStatus()){
        setCanSubmitUser(true)
    } else {
        setCanSubmitUser(false)
    }
}

const check_validation = (field) => {
    const calculateAge = (birthDate) => {
        let ageDifMs = Date.now() - birthDate.getTime(),
            ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    const check_single_field = (fieldName,value) => {
        if (fieldName === 'birth_date' && value.length) {
            let birthDate = new Date(value)
            if ((birthDate.getTime() < Date.now()) && (calculateAge(birthDate) >= 18)) return true
            return false
        } else if (fieldName === 'tax_id_code' && value.length) {
            let rx = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/
            if(rx.test(value)) return true
            return false
        } else if (fieldName === 'address_zip' && value) {
            if ((value <= 99999) && (value >= 10000)) return true
            return false
        /* } else if(['birth_date','tax_id_code','address_street','address_number','address_city','address_zip','password'].includes(fieldName) && !value.length) {
            return true */
        } else {
            if(value.length) return true
            return false
        }
    }
    let newVal = validation
    newVal[field.name].touched = true
    newVal[field.name].valid = check_single_field(field.name,field.value)
    setValidation({...newVal})
    checkIfCanSubmitUser()
}

const handleUserChange = (event) => {
    let dataCopy = userData
    if(event.target.type !== 'date'){
        dataCopy[event.target.name] = event.target.value
    } else {
        if (event.target.value!==null && event.target.value.length >=10) {
            let o = new Date(event.target.value),
                nd = o.getFullYear()+'-'+(o.getMonth()<9?'0':'')+(1+o.getMonth())+'-'+(o.getDate()<10?'0':'')+o.getDate()+'T'+(o.getHours()<10?'0':'')+o.getHours()+':'+(o.getMinutes()<10?'0':'')+o.getMinutes()                
            dataCopy[event.target.name] = nd.slice(0,10)
        }
    }
    setUserData({...dataCopy})
    check_validation(event.target)
}

const cancelModifications = (e) => {
    e.preventDefault()
    setUserLoaded(false)
}

const backtoHome = (e) => {
    e.preventDefault()
    dispatch(setSaved(false))
    setTriggerReloadCounters((triggerReloadCounters)=>triggerReloadCounters + 1)
}

const handleSave = async (e) => {
    e.preventDefault()
    setCanSubmitUser(false)
    const url = process.env.REACT_APP_LOCAL_AUTH_URL + apiModel + '/save'
    let postData = {}
    for(let k in userData){
        if(k !== 'birth_date'){
            if(userData[k]) postData[k]=userData[k]
        }
        if(k==='birth_date'){
            let o = new Date(userData[k]),
                nd = (o.getTime()/(1000*60*60*24))
            postData[k] = nd                
        }
    }
    const config = {
        headers: {'Authorization': 'Bearer ' + user.token},
        params: {}
    }
    try{
        const response = await axios.post(url,[postData],config)
        const recdata = response.data
            if (recdata) {
                if(recdata.result){
                    toast.success('Profilo aggiornato correttamente')
                    dispatch(setSaved(false))
                    setCanSubmitUser(false)
                    setTriggerReloadCounters((triggerReloadCounters)=>triggerReloadCounters + 1)
                } else {
                    toast.error('Errore durante l\'aggiornamento del profilo')
                }
            }
    } catch (error) {
        if(error.response.data.error){
            console.log(error.response.data.error.description)
            toast.error(error.response.data.error.description)
        } else {
            console.log('Errore durante l\'aggiornamento del profilo')
            toast.error('Errore durante l\'aggiornamento del profilo')
        }
    }
}

  return (
    <>
    <div className='container-sm' style={userProfileCompleted ? {marginTop: '300px'} : {marginTop: '100px'}}>
        <div className='row'>
            <div className='col-12 text-center p-3 wl-form rounded'>
                <h3 className='h3 my-3'>{'Gentile '+user.firstname+' '+user.lastname+',  ti ringraziamo per aver caricato la tua inserzione!'}</h3>

                {userProfileCompleted && <>
                    <h4 className='h4 mb-3'>La stiamo elaborando per pubblicarla sul sito. Segui le tue inserzioni qui:</h4>
                    <button className='btn mcr-btn btn-with-icon border border-even' title="Torna alla Home" onClick={() => {dispatch(setSaved(false));setTriggerReloadCounters((triggerReloadCounters)=>triggerReloadCounters + 1)}} ><span className="material-symbols-rounded">home</span></button>
                </>}
                {!userProfileCompleted && <>
                    <h4 className='h4 mb-3'>Per metterla online, abbiamo bisogno di sapere qualcosa in più su di te:</h4>

                    <div className='col-12 col-md-8 offset-md-2 col-lg-5 m-lg-auto p-2'>
            
                        <div className={'mt-1 mb-2'}>
                            <form className='wl-form border-even rounded shadow p-2'>

                            <div className='w-100 d-flex justify-content-end mt-1'>
                            <HtmlTooltip disableFocusListener enterTouchDelay={1} title="Per favore, completa le informazioni mancanti e salva il tuo profilo. Se non vuoi farlo ora, puoi tornare alla home e completare il tuo profilo in un secondo momento cliccando sul tuo nome e cognome nell'intestazione. Ricorda che, senza un profilo completo, non potremo approvare le tue inserzioni." placement="bottom" arrow>
                                <button className='btn mcr-btn success btn-with-icon ms-0' onClick={(e) => {e.preventDefault()}}><span className="material-symbols-rounded">question_mark</span></button>
                            </HtmlTooltip>
                            </div>

                            <input type='hidden' name='uuid' value={userData.uuid} placeholder='uuid...' readOnly/>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="birth_date">Data di nascita:</label>
                                </div>
                                <div className='col-8'>
                                <input type='date' autoComplete='birth_date' value={userData.birth_date} className={'form-control' + (!validation['birth_date'].valid && validation['birth_date'].touched ? ' is-invalid': '')} name='birth_date' placeholder='' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['birth_date'].valid || !validation['birth_date'].touched ? ' d-none': '')}>{validation['birth_date'].message}</span>
                                </div>
                            </div>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="tax_id_code">Codice fiscale:</label>
                                </div>
                                <div className='col-8'>
                                <input type='text' autoComplete='tax_id_code' value={userData.tax_id_code} className={'form-control' + (!validation['tax_id_code'].valid && validation['tax_id_code'].touched ? ' is-invalid': '')} name='tax_id_code' placeholder='Cod. fiscale...' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['tax_id_code'].valid || !validation['tax_id_code'].touched ? ' d-none': '')}>{validation['tax_id_code'].message}</span>
                                </div>
                            </div>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="address_street">Indirizzo:</label>
                                </div>
                                <div className='col-8'>
                                <input type='text' autoComplete='address_street' value={userData.address_street} className={'form-control' + (!validation['address_street'].valid && validation['address_street'].touched ? ' is-invalid': '')} name='address_street' placeholder='Via, Piazza...' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['address_street'].valid || !validation['address_street'].touched ? ' d-none': '')}>{validation['address_street'].message}</span>
                                </div>
                            </div>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="address_number">Numero civico:</label>
                                </div>
                                <div className='col-8'>
                                <input type='text' autoComplete='address_number' value={userData.address_number} className={'form-control' + (!validation['address_number'].valid && validation['address_number'].touched ? ' is-invalid': '')} name='address_number' placeholder='Numero civico...' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['address_number'].valid || !validation['address_number'].touched ? ' d-none': '')}>{validation['address_number'].message}</span>
                                </div>
                            </div>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="address_city">Città:</label>
                                </div>
                                <div className='col-8'>
                                <input type='text' autoComplete='address_city' value={userData.address_city} className={'form-control' + (!validation['address_city'].valid && validation['address_city'].touched ? ' is-invalid': '')} name='address_city' placeholder='Città di residenza...' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['address_city'].valid || !validation['address_city'].touched ? ' d-none': '')}>{validation['address_city'].message}</span>
                                </div>
                            </div>

                            <div className='row mt-3 mb-2'>
                                <div className='col-4 d-flex align-items-center'>
                                <label className="form-label" htmlFor="address_zip">CAP:</label>
                                </div>
                                <div className='col-8'>
                                <input type='number' autoComplete='address_zip' value={userData.address_zip} className={'form-control' + (!validation['address_zip'].valid && validation['address_zip'].touched ? ' is-invalid': '')} name='address_zip' placeholder='Cod. postale...' onChange={(e)=>{handleUserChange(e)}} />
                                </div>
                                <div className='col-12'>
                                    <span className={'text-even float-end' + (validation['address_zip'].valid || !validation['address_zip'].touched ? ' d-none': '')}>{validation['address_zip'].message}</span>
                                </div>
                            </div>

                            
                            <div className='form-footer mt-4 pb-3' style={{height: '48px'}}>
                                <button className='btn mcr-btn warning btn-with-icon ms-0 float-start' title="Annulla modifiche non salvate" onClick={(e) => {cancelModifications(e)}} ><span className="material-symbols-rounded">undo</span></button>
                                <button className='btn mcr-btn success btn-with-icon ms-0 float-end' title="Salva e procedi" onClick={(e) => {handleSave(e);return false}} disabled={!canSubmitUser} ><span className="material-symbols-rounded">done</span></button>
                                <button className='btn mcr-btn border-even btn-with-icon ms-0 float-end' title="Torna alla home" onClick={(e) => {backtoHome(e)}}><span className="material-symbols-rounded">home</span></button>
                            </div>

                            </form>
                        </div>
                        </div>
                </>}
            </div>
        </div>
    </div>
    </>
  )
}

export default Miniprofile
