import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const NotFound = () => {

  const user = useSelector((state)=> state.user)

  return (
    <div className={'hero d-flex justify-content-center align-items-center' + (user.capabilities.includes('cap_admin')?' text-even':'')}>
        <div className='text-center'>
            <h1 className={(user.capabilities.includes('cap_admin')?'text-even':'')}>404</h1>
            <h2 className={(user.capabilities.includes('cap_admin')?'text-even':'')+' mb-3'}>Elemento non trovato</h2>
            <NavLink className={(user.capabilities.includes('cap_admin')?'text-even':'dark-link')} style={{textDecoration: 'none', fontSize: '24px'}} to={'/dashboard'}>
                <span className="material-symbols-rounded" style={{position: 'relative', top: '3px'}} >home</span> Torna alla home</NavLink>
        </div>
    </div>
  )
}

export default NotFound
