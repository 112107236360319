import React, { useEffect, useState,  useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logIn, logOut, saveLoginInLocalStorage, setCapabilities, setCode, setUserId, setUserName } from '../app/store'
import { Link, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { generate_rnd_string, sha256_and_base64_encode } from '../helper'
import { ToastContainer, toast } from 'react-toastify';
import { getUserInfo, loadModels } from '../common'

const Login = () => {

    const user = useSelector((state)=> state.user)
    const [logginIn,setLogginIn] = useState(false)
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()

    /* Refresh token logic when near expiration done in App.js */

    const exchangeReceivedCodeForToken = useCallback(async(code) => {
      const url = process.env.REACT_APP_LOCAL_AUTH_URL + 'session/token'
      const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params: {}
      }
      const postData = {
        'grant_type': 'authorization_code',
        'client_id': process.env.REACT_APP_CLIENT_ID,
        'code': code,
        'code_verifier': user.code,
        'redirect_uri': process.env.REACT_APP_LOCAL_FRONTEND_URL,
      }
      try{
        const response = await axios.post(url,postData,config)
        if(response.data.error){
          if(response.data.error !== 'invalid_request'){
            toast.error(response.data.error)
          } else {
            console.log('Richiesta invalida in fase di Login (pulire la stringa dell\'url dai vecchi parametri)')
          }
        } else {
          return response.data
        }
      } catch (error){
        toast.error('Errore di rete in fase di Login')
      }
      return null
    },[user.code])

    // After login backend returns a code and other GET params, make axios call to get actual token and capabilities (STEP 2)
    useEffect(()=>{
      if(user.code && !user.logged_in){
        if(searchParams && searchParams.get('code')) {
          setLogginIn(true)
          exchangeReceivedCodeForToken(searchParams.get('code')).then(
            (data)=>{
              setLogginIn(false)
              if(data && data.access_token){
                dispatch(logIn(
                  { token: data.access_token, refreshToken: data.refresh_token, expires: data.expires_in }
                  ))
                dispatch(saveLoginInLocalStorage())
                // Get capabilities
                getUserInfo(process.env.REACT_APP_LOCAL_AUTH_URL,data.access_token).then((result)=>{
                  if(result.userdata){
                      let caps = result.userdata.capabilities,
                          user = result.userdata.user,
                          name = {
                            firstname: result.userdata.given_name,
                            lastname: result.userdata.family_name,
                            username: result.userdata.username
                          }
                      dispatch(setUserName(name))
                      dispatch(setUserId(user))
                      loadModels(process.env.REACT_APP_LOCAL_AUTH_URL,'capability',caps,data.access_token).then((result)=>{
                        if(result.result){
                          let rs = result.result,
                              caps_names = []
                          for(let k in rs){
                            caps_names.push(rs[k].code)
                          }
                          // Check if user is has capabilities, otherwise kick out
                          if(caps_names.length){
                            dispatch(setCapabilities(caps_names))
                          } else {
                            console.log('Errore: utente privo di capabilities')
                            dispatch(logOut())
                          }
                        } else {
                          toast.error('Errore nell\'ottenere i nomi dei permessi utente')
                        }
                      })
                  } else {
                    toast.error('Errore nell\'ottenere i permessi utente')
                  }
                })
              } else {
                toast.error('Errore nell\'ottenere il token di accesso')
              }
            }
          )
        } else if (searchParams && searchParams.get('error')) {
          console.log('Login error: ' + searchParams.get('error'))
          toast.error('Nome utente o password errati')
        }
      }
    },[searchParams,user.logged_in,user.code,exchangeReceivedCodeForToken,dispatch])

    // Make redirect to login UI with all parameters set (STEP 1)
    const handleLogin = async () => {
        let code = generate_rnd_string(6),
            state = '1234',
            encoded_code = await sha256_and_base64_encode(code)
        dispatch(setCode(code))
        document.location.href = process.env.REACT_APP_LOCAL_AUTH_URL + 'session/authorize/?client_id='+process.env.REACT_APP_CLIENT_ID+'&response_type=code&state=' + state +
          '&redirect_uri=' + process.env.REACT_APP_LOCAL_FRONTEND_URL + '&scope=&code_challenge=' + encodeURIComponent(encoded_code) + '&code_challenge_method=S256'
    }

  return (
    <div className='' style={{marginTop: 0}}>
      <ToastContainer
          className='toast-lowered'
          position='top-center'
          theme='dark'
        />
      <div className='hero d-flex justify-content-center align-items-center' style={{paddingBottom: 50}}>
        <div className='login-square p-5'>

        {!logginIn && <><div className='row'>
          <div className='col-12 text-center'>
            <button className='btn mcr-btn mx-0 font-futura' onClick={handleLogin}>Login</button>
          </div>
        </div>
        
        <div className='row text-white'>
          <div className='col-12 text-center mt-4'>
            <h5 className='h5 font-futura'>Non hai un account?</h5>
          </div>
        </div>
        <div className='row text-white'>
          <div className='col-12 text-center mt-2'>
            <Link className='even-link' to='/register'><h4 className='h4 font-futura-bold'>Crea il tuo profilo venditore</h4></Link>
          </div>
        </div></>}

        </div>
      </div>
    </div>
  )
}

export default Login