import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get_field_apimodel, get_html_field_type, get_field_value, get_checked, get_html_field_classname, get_html_fieldlabel_classname, get_post_object_field_value, get_field_isvalid,check_field_validation, get_final_ui_structure, get_error_message_by_code, get_chunkarray_by_fileinfo, get_resource_filename} from '../helper'
import AutocompleteInput from '../fields/Autocomplete'
import MultiAutoComplete from '../fields/MultiAutoComplete'
import { setTriggerReload } from '../app/store'
import useWindowSize from '../hooks/Windowsize'
import useScrollbarWidth from '../hooks/ScrollbarWidth';
import { createResource, deleteResource, saveResource, uploadFileChunks } from '../common';
import FileUpload from '../fields/FileUpload';
import { ReactComponent as Spinner } from '../loading_spinner.svg'
import Photogallery from '../fields/Photogallery'
import UserInfo from './UserInfo';

const Newform = (props) => {
    // eslint-disable-next-line no-unused-vars
    const {nomeSing,url,apiModel,enableSave,baseUrl,MEDIA_URL,additionalFilter} = props // do not remove additionalFilter, is needed for tableComponent to receive it when opening edit/new view

    const [loading1, setloading1] = useState(false)
    const [loadErr, setLoadErr] = useState(null)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [objectData, setObjectData] = useState({})
    const [modifiedFields, setModifiedFields] = useState({})
    const [prepopulateAutocompleteString,setPrepopulateAutocompleteString] = useState('') // for product field in offer matching
    const [committed, setCommitted] = useState(false)
    const [validation, setValidation] = useState([])
    const [fileInfos, setFileInfos] = useState([])
    const [chunkDatas, setChunkDatas] = useState([])
    const [rendered, setRendered] = useState(false)
    const [finalRenderingStructure, setFinalRenderingStructure] = useState([])
    const [gallerySize,setGallerySize] = useState(120)

    const structureData = useSelector((state)=> state.dataStructure.structure)
    const uiStructure = useSelector((state)=> state.uiStructure.structure)
    const serviceCode = useSelector((state)=> state.dataStructure.serviceCode)
    const loadedCount = useSelector((state)=> state.loadedCount)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()

    const {id} = useParams()
    const navigate = useNavigate()

    const windowSize = useWindowSize()
    const scrollbarWidth = useScrollbarWidth()

    // For slide animation to be displayed
    useEffect(()=>{setRendered(true)},[])

    useEffect(()=>{
      let finalUIstructure = get_final_ui_structure(uiStructure,structureData)
      setFinalRenderingStructure(finalUIstructure)
    },[structureData,uiStructure])

    useEffect(()=>{
      setValidation([])
      let validated=check_field_validation(structureData,objectData)
      setValidation(validated)
    },[structureData,objectData])

    // Set prepopulate string for product field in offer edit if product is not already selected
    useEffect(()=>{
      if(objectData.request_wine && objectData.request_brand && (objectData.year || objectData.is_single_vintage) && !objectData.product){
        setPrepopulateAutocompleteString(objectData.request_wine + ' ' + objectData.request_brand + ' ' +(objectData.year?objectData.year:''))
      }
    },[objectData,prepopulateAutocompleteString])

    useEffect(() => {
        setloading1(true)
        setLoadErr(null)
        const getData = async() => {
          const url = baseUrl ? baseUrl + apiModel + '/create/1' : process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/create/1';
          const config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
          }
          try{
            const response = await axios.get(url,config)
            const recdata = response.data.result[0]
            if(recdata){
              if(recdata.committed){
                setCommitted(true)
              }else{
                setCommitted(false)
              }
              setObjectData(recdata)
            }
            setloading1(false)
          } catch(error){
            if(error.response.data.error.code){
              setLoadErr(get_error_message_by_code(error.response.data.error.code))
              toast.error(get_error_message_by_code(error.response.data.error.code))
            } else {
              setLoadErr('Errore di rete generando l\'oggetto!')
              toast.error('Errore di rete generando l\'oggetto!')
            }
            setloading1(false)
          }
        }
        getData()
      },[setloading1,id,apiModel,baseUrl,user.token]
      )

      const handleChange = (value, field, chunkData, fileInfo) => { // If receive chunkData and fileInfo, is a resource field
        let objectData2 = objectData
        let modifiedFields2 = modifiedFields
        
        if(fileInfo && chunkData){
    
          // Set fileInfos state to manage meta info for all resource fields
          let fileInfos2 = []
          for (let k in fileInfos){
            if(fileInfos[k].field_name !== field.name) fileInfos2.push(fileInfos2[k])
          }
          fileInfos2.push(fileInfo)
          setFileInfos(fileInfos2)
    
          // Set chunkDatas state to data streams for all resource fields
          let chunkDatas2 = []
          for (let k in chunkDatas){
            if(chunkDatas[k].field_name !== field.name) chunkDatas2.push(chunkDatas2[k])
          }
          chunkDatas2.push(chunkData)
          setChunkDatas(chunkDatas2)
    
          // Create a new uuid for the resource
          setloading1(true)
          createResource(MEDIA_URL,field.model,user.token).then((result)=>{
            if(result.error){
              toast.error(result.error)
            } else if (result.uuid) {
    
              fileInfo.uuid = result.uuid
              setloading1(false)
              objectData2[field.name] = result.uuid
              modifiedFields2[field.name] = result.uuid
              if(!committed){
                objectData2['committed']=1
                modifiedFields2['committed']=1
                setCommitted(true)
              }
              setObjectData({ ...objectData2 })
              setModifiedFields({ ...modifiedFields2 })
            }
          })
        } else {
          objectData2[field.name] = get_post_object_field_value(field, value)
          modifiedFields2[field.name] = objectData2[field.name]
          if(!committed){
            objectData2['committed']=1
            modifiedFields2['committed']=1
            setCommitted(true)
          }
          setObjectData({ ...objectData2 })
          setModifiedFields({ ...modifiedFields2 })
        }
      }

    const handleSave = async () => {
      let validForm = true
      for (let k in validation) {
        if (!validation[k].valid) {
          validForm = false
          toast.error(validation[k].message)
        }
      }
      if(!validForm) return false
      if(objectData.uuid){
        //setSaved(false)
        //setSaveErr(null)
        setBtnDisabled(true)
        setloading1(true)
        const saveNow = async() => {
          const url = baseUrl ? baseUrl + apiModel + '/save' : process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/save';
          const config = {
            headers: {'Authorization': 'Bearer ' + user.token},
          }
          let data = modifiedFields
          data.uuid=objectData.uuid
          const postData = [data]
          try {
            const response = await axios.post(url,postData,config)
            if(response.data.result === true){
              //setSaved(true)
              toast.success("Salvato con successo")
              //setSaved(false)
              setBtnDisabled(false)
              setloading1(false)
              dispatch(setTriggerReload(loadedCount.triggerReload+1))
              let {url} = props // Need to reinitialize with props because of url variable set on first line of function
              navigate(url+'/'+objectData.uuid)
              return true
            } else {
              //setSaveErr(response.data.error)
              toast.error(response.data.error)
              //setSaveErr(null)
              setBtnDisabled(false)
              setloading1(false)
            }
            setModifiedFields({})
            return false
          } catch(error){
              if(error.response.data.error.code){
                //setSaveErr(get_error_message_by_code(error.response.data.error.code))
                toast.error(get_error_message_by_code(error.response.data.error.code))
              } else {
                //setSaveErr('Errore di rete al salvataggio!')
                toast.error('Errore di rete al salvataggio!')
              }
              //setSaveErr(null)
              setBtnDisabled(false)
              setloading1(false)
              return false
          } 
        }
        
        if(fileInfos.length){ // If some resource file has to be uploaded before saving
          uploadFiles().then((result) => {
            if(result.error){
              toast.error(result.error)
              return false
            } else {
              saveFiles().then((result) => {
                if(result.error){
                  toast.error(result.error)
                  return false
                } else {
                  deleteReplacedFiles().then((result) => {
                    if(result.error){
                      toast.error(result.error)
                      return false
                    } else {
                      return saveNow()
                    }
                  })
                }
              })
            }
          })
          setBtnDisabled(false)
          setloading1(false)
        } else {
          return saveNow()
        }
      }
    }

    const deleteReplacedFiles = async() => {
      for(let k in fileInfos){
  
        let result = fileInfos[k].dependent ? await deleteResource(MEDIA_URL,fileInfos[k],user.token) : {"status": 'ok'}
        if (result.status && result.status === 'ok') {
          if(parseInt(k) === (fileInfos.length)-1){
            // All files deleted
            return result
          } else {
            continue
          }
        } else {
          // Deleted failed
          return result
        }
  
      }
    }
  
    const saveFiles = async() => {
      for(let k in fileInfos){
        let result = await saveResource(MEDIA_URL,fileInfos[k],objectData.uuid,apiModel,serviceCode,user.token)
        if (result.status && result.status === 'ok') {
          if(parseInt(k) === (fileInfos.length)-1){
            // All files saved
            return result
          } else {
            continue
          }
        } else {
          // Save failed
          return result
        }
      }
    }
  
    const uploadFiles = async() => {
      for(let k in fileInfos){
  
        let chunks = get_chunkarray_by_fileinfo(fileInfos[k],chunkDatas)
        if(chunks && chunks.length){
          let result = await uploadFileChunks(MEDIA_URL,fileInfos[k],chunks,user.token)
          if (result.status && result.status === 'ok') {
            if(parseInt(k) === (fileInfos.length)-1){
              // All files uploaded
              return result
            } else {
              continue
            }
          } else {
            // Upload failed
            return result
          }
        } else {
          return {"error": 'Il file ' + fileInfos[k].field_name + ' scelto non contiene dati'}
        }
  
      }
    }

    const handleSaveAndBack = () => {
      handleSave().then((result) => {
        if (result){
          handleBack()
        }
      })
    }

    const handleBack = () => {
      setRendered(false)
      setTimeout(
        ()=>{navigate(process.env.REACT_APP_LOCAL_SUBFOLDER + url)},500
      )
    }

  return (
    <>
    <div className={"overlay"+(rendered ? ' visible' : '')} style={loading1 ? {overflow: 'hidden'} : {}}>
    <div className="container-fluid px-0">
      
      <div className={'bg-dark form-header'+(rendered ? ' visible' : '')+ ' pt-4 pb-3'}>
        <div className="row">
          <div className="col-12 col-md-2 col-lg-3 col-xl-4">
          <button className='btn mcr-btn btn-with-icon icon-back ms-4 ms-md-2 mb-2' onClick={() => {handleBack()}}><span className="material-symbols-rounded">arrow_back</span></button>
          </div>
          <div className="col-12 col-md-8 col-lg-6 col-xl-4 pb-2">
            <h3 className='text-even text-start text-md-center ms-4 ms-md-0'>Nuovo {nomeSing}</h3>
          </div>
        </div>
      </div>

        {/* loading1 && <h3 className='h3 text-even mt-5 pt-5 ms-3'>Caricamento...</h3> */}
        {loading1 && <div className='loading-overlay d-flex justify-content-center align-items-center'><Spinner height={64} width={64}/></div>}
        {!loading1 && loadErr && <h3 className='h3 text-even mt-5 pt-5 ms-3'>{loadErr}</h3>}
        {!loading1 && objectData && structureData && <div className='w-100 px-2'>

        {!loadErr && <div className={"overlay-top-buttons-container w-100 py-1 px-2"} style={rendered ? {width: 'calc(100vw - '+(400+scrollbarWidth)+'px)', maxWidth: (990-scrollbarWidth )+'px'}: {}} >
            <div className='row'>
            <div className="col-12 text-end">
              {!loadErr && enableSave && <button className='btn mcr-btn btn-with-icon ms-0' title="Salva" onClick={() => { handleSave() }} disabled={btnDisabled}><span className="material-symbols-rounded">save</span></button>}
              {!loadErr && enableSave && <button className='btn mcr-btn success btn-with-icon ms-0' title="Salva e chiudi" onClick={() => { handleSaveAndBack() }} disabled={btnDisabled}><span className="material-symbols-rounded">done</span></button>}
            </div>
            </div>
          </div>}

            <div className='row mb-3' style={windowSize!=='sm'?{marginTop: '130px'}:{marginTop: '170px'}}>

            {apiModel === 'offer' && <div className="col-12 col-md-12 col-lg-6 mt-3"><UserInfo userid={objectData.user} /></div>}

            {
                !loadErr && finalRenderingStructure.length && finalRenderingStructure.map((field, idx) => {
                  if(field.section)return <React.Fragment key={idx}>
                    <div className='col-12'>
                      <label className='form-label text-even mt-3 h4 mt-4'>{field.name}</label>
                      <hr className='text-even mt-0'/>
                    </div>
                    </React.Fragment>
                  else
                  return <React.Fragment key={idx}>
                    <div className={"col-12 col-md-"+(field.format?field.format:'6')+" " +(get_html_fieldlabel_classname(field)==='d-none'?'d-none':'')}>
                      <label
                        className={get_html_fieldlabel_classname(field,true)}>
                        <b>
                          {(field.label ? field.label.charAt(0).toUpperCase()+field.label.slice(1) : field.name.charAt(0).toUpperCase()+field.name.slice(1)) + (field.name === 'price' ? ' €' : '')}
                        </b>
                        {field.name === 'product' && !get_field_value(field, objectData) && <b className='ms-2 text-red'>(MATCHING NON ESEGUITO)</b>}
                      </label>
                      {!['uuid','uuid[]','longtext'].includes(field.type) && <input
                      disabled={(get_html_field_type(field)==='checkbox' && field.readonly) || (get_html_field_type(field)==='checkbox' && field.name === 'winevalue_origin')}
                      readOnly={field.name==='created' || field.readonly} 
                      name={field.name}
                      required={field.required}
                      className={get_html_field_classname(field,true) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      type={get_html_field_type(field)}
                      value={get_field_value(field, objectData) ?? ''}
                      min={get_html_field_type(field)==='number'?field.min:null}
                      max={get_html_field_type(field)==='number'?field.max:null}
                      checked={get_checked(field, objectData)}
                      onChange={(e) => { handleChange(e.target.value, field) }} 
                      title={(field.name==='created' || field.readonly)?'Non modificabile':''}
                    />}
                    {field.type === 'longtext' && <textarea 
                      readOnly={field.name==='created' || field.readonly} 
                      name={field.name}
                      required={field.required}
                      className={get_html_field_classname(field) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      type={get_html_field_type(field)}
                      value={get_field_value(field, objectData) ?? ''}
                      min={get_html_field_type(field)==='number'?field.min:null}
                      max={get_html_field_type(field)==='number'?field.max:null}
                      checked={get_checked(field, objectData)}
                      onChange={(e) => { handleChange(e.target.value, field) }}
                      title={(field.name==='created' || field.readonly)?'Non modificabile':''}
                    ></textarea>}
                    {field.type === 'uuid' && field.name !== 'uuid' && field.model !== 'resource' && <AutocompleteInput
                      makeDisable={field.readonly}
                      apiSubModel={get_field_apimodel(field, structureData)}
                      value={get_field_value(field, objectData) ?? ''}
                      autoCompleteClass={get_html_field_classname(field) + ' material-form-control' + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      field={field}
                      baseUrl={baseUrl}
                      changeHandler={handleChange}
                      prepopulateString={field.name === 'product' ? prepopulateAutocompleteString: ''} // For product matching
                    />}
                    {/* File Upload */}
                    {field.type === 'uuid' && field.name !== 'uuid' && field.model === 'resource' && <FileUpload
                      value={get_field_value(field, objectData) ?? ''}
                      fileName={get_resource_filename(field, fileInfos)}
                      field={field}
                      apiSubModel={get_field_apimodel(field, structureData)}
                      className={get_html_field_classname(field) + (!get_field_isvalid(field,validation) ? ' is-invalid' : '')}
                      makeDisable={field.readonly}
                      MEDIA_URL={MEDIA_URL}
                      dependent={field.dependent}
                      changeHandler={handleChange}
                    />}
                    {field.type === 'uuid[]' && (field.model !== 'resource') && !field.model.includes('image') && <MultiAutoComplete
                      makeDisable={field.readonly}
                      apiSubModel={get_field_apimodel(field, structureData)}
                      fieldName={field.name}
                      initMultiValue={get_field_value(field, objectData) ?? ''}
                      isValid={get_field_isvalid(field,validation)}
                      field={field}
                      changeHandler={handleChange}
                      baseUrl={baseUrl}
                    />}
                    {field.type === 'uuid[]' && field.model.includes('image') && <Photogallery
                      field={field}
                      height={gallerySize}
                      setHeightFunction={setGallerySize}
                      imageModel={'resource'}
                      imageContainerModel={field.model}
                      parentModel={apiModel}
                      parentModelUuid={objectData.uuid}
                      baseUrl={baseUrl}
                      mediaUrl={MEDIA_URL}
                      readOnly={!enableSave || field.readonly}
                      changeHandler={handleChange}
                      initMultiValue={get_field_value(field, objectData) ?? ''}
                    />}
                    </div>
                    </React.Fragment>
                }) }
                </div>
              {/* {saved && <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-primary'><b>Salvato con successo!</b></p></div>
                </div>}
              {saveErr && <div className="row">
                  <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"><p className='text-danger'><b>{saveErr}</b></p></div>
              </div>} */}

        </div>}
    </div>
    </div>
    </>
  )
}

export default Newform
