import { useState, useEffect } from "react";

function useWindowHeight() {
  const isWindowClient = typeof window === "object"

  const [windowHeight, setWindowHeight] = useState(
    isWindowClient
      ? window.innerHeight
      : undefined
  );

  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowHeight(window.innerHeight)
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener("resize", setSize)

      //unregister the listerner on destroy of the hook
      return () => window.removeEventListener("resize", setSize)
    }
  }, [isWindowClient, setWindowHeight])

  return windowHeight
}

export default useWindowHeight
