import React from 'react'
import { useSelector } from 'react-redux';
import Topbar from '../components/Topbar';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Centralbody from '../components/Centralbody';

const Home = (props) => {

  const user = useSelector((state)=> state.user)

  const {links,url,nomeSing,nomePlur,apiModel,enableSave,enableDelete,enableNew,baseUrl,MEDIA_URL,additionalFilter} = props

  return (
    <>
    <Topbar url={url} nomePlur={nomePlur} />
    <section className='centralbody-container d-flex'>
      {user.capabilities.includes('cap_admin') && <Menu links={links} />}
      <Centralbody url={url} nomeSing={nomeSing} nomePlur={nomePlur} apiModel={apiModel} enableSave={enableSave} enableDelete={enableDelete} enableNew={enableNew} baseUrl={baseUrl} MEDIA_URL={MEDIA_URL} links={links} additionalFilter={additionalFilter} />
    </section>
    {(user.logged_in && user.capabilities.includes('cap_admin')) && <Footer />}
    </>
  )
}

export default Home
